import React from 'react'
import Text from '@component/Text/Text'
import UserThumbnail from '@component/images/UserThumbnail'
import useFormatNumber from '@hook/useFormatNumber'
import {getNumberColorClass} from '@util/etc'
import {useTranslation} from 'next-i18next'
import {getDayDifferenceFromToday} from '@util/dates'
import RiskTag from '@component/tags/RiskTag'
import {ICopyLeaderStartCopy} from '@api/copy/leader/getLeaderKeysForStartCopy'
import ExchangeTag from '@component/tags/ExchangeTag'

interface IProps extends ICopyLeaderStartCopy {
    className?: string
    isSelected?: boolean
    onClick?: (item: ICopyLeaderStartCopy) => void
}

const SelectLeaderKeyItem: React.FC<IProps> = props => {
    const {
        className,
        isSelected = false,
        thumbnail,
        copy_lead_name,
        exchange,
        all_time_profit,
        current_balance,
        min_balance,
        copier,
        max_copier,
        started_at,
        mdd,
        risk,
        is_paid_leader,
        onClick,
    } = props

    const {t} = useTranslation()
    const {displayBalance, displayPercent, displayNumber, displayBalanceShort} = useFormatNumber()
    const leaderHasEnoughCopierSheet = copier < max_copier

    const getLeaderStartedDay = () => {
        if (getDayDifferenceFromToday(started_at) < 1) {
            return t('modal.createCopy.step1.selectLeaderKeyItem.started.new')
        }

        return t('modal.createCopy.step1.selectLeaderKeyItem.started.content', {
            day: displayNumber(getDayDifferenceFromToday(started_at)),
        })
    }

    return (
        <div
            onClick={() => onClick(props)}
            className={`border rounded-[5px] py-[20px] px-[30px] sm:px-[20px] mb-[20px] cursor-pointer ${className} ${
                isSelected
                    ? leaderHasEnoughCopierSheet
                        ? 'border-brown dark:border-dark_brown bg-brown_tint01 dark:bg-dark_brown_tint01'
                        : 'bg-red_tint02 dark:bg-dark_red_tint02 border-red dark:border-dark_red'
                    : 'bg-brown_tint02 dark:bg-dark_brown_tint02 border-transparent02 dark:border-dark_transparent hover:bg-brown_tint01 dark:hover:bg-dark_brown_tint01'
            }`}>
            <div className={'relative w-full flex gap-[15px]'}>
                <UserThumbnail containerClassName={'sm:hidden'} src={thumbnail} size={60} />
                <div className={'flex justify-between w-full gap-x-[5px] sm:items-center'}>
                    <div>
                        <div className={'flex gap-x-[5px] items-center sm:flex-col sm:items-start'}>
                            <Text
                                className={`text-h2 text-gray01 dark:text-dark_gray01 ${
                                    copy_lead_name?.length > 12 ? 'sm:text-ti1' : 'sm:text-h3'
                                }`}>
                                {copy_lead_name}
                            </Text>
                        </div>
                        <div className={'flex items-center gap-x-[20px] mt-[5px] sm:hidden'}>
                            <div className={'flex items-center gap-[5px]'}>
                                <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                                    {t('modal.createCopy.step1.selectLeaderKeyItem.started.title')}
                                </Text>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                    {getLeaderStartedDay()}
                                </Text>
                            </div>
                            <div className={'flex items-center gap-[5px] '}>
                                <Text className={'text-body2 text-gray02 dark:text-dark_gray02 '}>
                                    {t('modal.createCopy.step1.selectLeaderKeyItem.mdd.title')}
                                </Text>
                                <div className={'flex justify-center gap-x-[10px]'}>
                                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                        {displayPercent(mdd)}
                                    </Text>
                                    <RiskTag risk={risk} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Text className={`text-h2 sm:text-h3 text-right ${getNumberColorClass(all_time_profit)}`}>
                            {displayPercent(all_time_profit)}
                        </Text>
                        <div className={'flex gap-x-[5px] justify-end sm:hidden'}>
                            <Text className={`text-body2 text-gray02 dark:text-dark_gray02`}>
                                {t('modal.createCopy.step1.selectLeaderKeyItem.balance')}
                            </Text>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {displayBalanceShort(current_balance, {showPreSign: true})}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`mt-[15px] bg-white dark:bg-bg_dark_white02 dark:bg-opacity-100 bg-opacity-50 w-full px-[20px] sm:px-[15px] py-[10px] rounded-[5px] ${
                    !leaderHasEnoughCopierSheet ? 'bg-red_tint02 dark:bg-dark_red_tint02' : ''
                }`}>
                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                    {t('modal.createCopy.step1.selectLeaderKeyItem.require.title')}
                </Text>
                <div className={'grid grid-cols-2 mt-[5px] sm:block'}>
                    <div className={'flex items-center sm:justify-between'}>
                        <Text className={'text-gray02 dark:text-dark_gray02 pr-[10px]'}>
                            {t('modal.createCopy.step1.selectLeaderKeyItem.requirements.exchange.label')}
                        </Text>
                        <ExchangeTag
                            exchange={exchange}
                            iconSize={16}
                            textClassName={'text-ti1'}
                            iconClassName={'sm:hidden'}
                        />
                        {exchange === 'binance' ? (
                            <Text className={'text-gray02 dark:text-dark_gray02 pl-[5px] sm:hidden'}>
                                {t('modal.createCopy.step1.selectLeaderKeyItem.requirements.exchange.binance.label')}
                            </Text>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className={'flex justify-end space-x-[10px] sm:justify-between'}>
                        <Text className={'text-gray02 dark:text-dark_gray02'}>
                            {t('modal.createCopy.step1.selectLeaderKeyItem.requirements.minimumBalance.label')}
                        </Text>
                        <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                            {displayBalance(min_balance, {showPreSign: true})}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectLeaderKeyItem
